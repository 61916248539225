.button {
  flex: 1 0 0;
  height: 60px;
  margin-top: 28px;
}

.input-container {
  flex: 1 0 0;
}

.input-inner-container.input-inner-container {
  max-width: unset;
}

@media (max-width: 48em) {
  .flex-container {
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    width: 100%;
    margin-top: 0px;
  }
}
